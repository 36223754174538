.scroll-to-top-button {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 3.8rem;
  height: 3.8rem;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(145deg, #ff8c00, #ff6b00);
  box-shadow: 0 4px 15px rgba(255, 140, 0, 0.3),
              inset 0 -2px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  z-index: 1000;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 20px rgba(255, 140, 0, 0.4),
                inset 0 -2px 5px rgba(0, 0, 0, 0.1);

    .arrow {
      transform: translateY(-4px);
      text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    }
  }

  &:active {
    transform: translateY(-2px);
    box-shadow: 0 3px 10px rgba(255, 140, 0, 0.3),
                inset 0 -1px 3px rgba(0, 0, 0, 0.1);
  }

  .arrow {
    color: white;
    font-size: 2rem;
    line-height: 1;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    padding-bottom: 0.2rem;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background: linear-gradient(145deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
    pointer-events: none;
  }
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .scroll-to-top-button {
    bottom: 2rem;
    right: 2rem;
    width: 3.2rem;
    height: 3.2rem;

    .arrow {
      font-size: 1.6rem;
    }
  }
}
